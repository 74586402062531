"use client";

import styles from "./ImageSlider.module.scss";
import SimpleImageSlider from "react-simple-image-slider";

const SLIDE_DURATION = 1;
const SLIDE_DELAY = 10;

const ImageSlider = ({ src }: { src: string[] }) => {
  return (
    <div className={styles.container}>
      <SimpleImageSlider
        width={"100%"}
        height={"100%"}
        images={src}
        showBullets={true}
        showNavs={false}
        slideDuration={SLIDE_DURATION}
        autoPlay={true}
        autoPlayDelay={SLIDE_DELAY}
      />
    </div>
  );
};

export default ImageSlider;
