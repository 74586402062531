// 1. Basic Properties
import { InputTypes } from "@components/Input/enums";
import { ErrorTypes } from "@utils/Validation/getValidationMessage";
import { CSSProperties, FocusEvent, FormEvent, KeyboardEvent, MouseEvent, ReactNode } from "react";
import { InputDesign } from "@components/Input/TextInput";

export interface BasicInputProps {
  children?: ReactNode | ReactNode[];
  placeholder?: string;
  name: string;
  label?: string;
  description?: string;
  value: string | number;
  type?: keyof typeof InputTypes;
  autoComplete?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  min?: number;
}

export const DefaultInputType: keyof typeof InputTypes = "text";

// 2. Event Handlers
export interface InputEventHandlers {
  onChange?: (value: string) => void;
  onClick?: (e: MouseEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onMouseEnter?: (e: MouseEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onMouseLeave?: (e: MouseEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onInput?: (e: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
}

// 3. Design & Style
export interface DesignProps {
  className?: string;
  design?: keyof typeof InputDesign;
  style?: CSSProperties;
  height?: string | number;
  minHeight?: string | number;
  width?: string | number;
  minWidth?: string | number;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  borderRadius?: string | number;
  margin?: string | number;
  padding?: string | number;
}

// 4. Validation & Error
export interface InputError {
  type: ErrorTypes;
  message?: string;
}

export interface ValidationProps {
  required?: boolean;
  validation?: {
    type: ErrorTypes;
    message?: string;
  };
  onError?: (value: InputError) => void;
  removeError?: (key: ErrorTypes) => void;
  externalError?: InputError | null;
}

// Finally, combine all the smaller interfaces into one:
export type InputInterface = BasicInputProps & InputEventHandlers & DesignProps & ValidationProps;
