import {
  validateAddress,
  validateEmail,
  validateMessage,
  validateName,
  validatePassword,
  validatePhoneNumber,
  validateProvince,
  validateZip
} from "@utils/Validation/validation";

export enum ErrorTypes {
  Server = "server",
  Required = "required",
  Name = "name",
  Email = "email",
  Identifier = "identifier",
  Password = "password",
  PhoneNumber = "phonenumber",
  Zip = "zip",
  City = "city",
  Company = "company",
  Address = "address",
  Message = "message"
}

const getValidationMessage = (type: ErrorTypes, value: string) => {
  switch (type) {
    case ErrorTypes.Name:
      if (checkEmpty(value) && value?.length >= 3) {
        return;
      }
      return validateName.invalid;
    case ErrorTypes.Email:
      if (validateEmail.regex.test(value)) {
        return;
      }
      return validateEmail.invalid;
    case ErrorTypes.Identifier:
      if (validateEmail.regex.test(value)) {
        return;
      }
      return validateEmail.invalid;
    case ErrorTypes.Password:
      if (checkEmpty(value) && value?.length >= validatePassword.minLength.length) {
        return;
      }
      return validatePassword.minLength.message;
    case ErrorTypes.PhoneNumber:
      if (checkEmpty(value) && !isNaN(Number(value))) {
        return;
      }
      return validatePhoneNumber.invalid;
    case ErrorTypes.Zip:
      // check if number
      if (checkEmpty(value) && value?.length > 3 && !Number.isNaN(Number(value))) {
        return;
      }
      return validateZip.invalid;
    case ErrorTypes.City:
      if (checkEmpty(value) && value?.length > 3) {
        return;
      }
      return validateProvince.invalid;
    case ErrorTypes.Address:
      if (checkEmpty(value) && value?.length > 3) {
        return;
      }
      return validateAddress.invalid;
    case ErrorTypes.Message:
      if (checkEmpty(value) && value?.length > 3) {
        return;
      }
      return validateMessage.required;
    default:
      if (checkEmpty(value) && value?.length >= 3) {
        return;
      }
      return validateName.invalid;
  }
};

export default getValidationMessage;

const checkEmpty = (value: string) => {
  const trim = value.trim().length;
  return trim >= 3;
};

export const formatName = (name: string) => {
  const nameArray = name.split("_");
  let nameWithoutDeliveryInvoice = nameArray[nameArray.length - 1];
  if (nameWithoutDeliveryInvoice === "number") nameWithoutDeliveryInvoice = "phonenumber";
  return nameWithoutDeliveryInvoice;
};
