// TODO: add error messages to checkout, contact us, user registration/profile user change

export const validateEmail = {
  regex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  required: "Email is required",
  invalid: "Please enter a valid email address",
  empty: "We think you forgot to enter an email address"
};

export const validatePassword = {
  required: "Password is required",
  minLength: {
    length: 6,
    message: "Password must be at least 6 characters long"
  },
  symbol: "Password must contain at least one symbol",
  number: "Password must contain at least one number",
  match: "Passwords do not match",
  wrong: "Wrong password",
  forbidden: "Forbidden, try resetting your password again",
  expired: "Request has expired or is no longer valid",
  empty: "No password was entered"
};

export const validateName = {
  required: "Name is required",
  invalid: "Please enter a valid first and last name",
  empty: "We think you forgot to enter a name"
};

export const validateUserName = {
  required: "Username is required",
  invalid: "Please enter a valid username",
  minLength: "Username must be at least 3 characters",
  maxLength: "Username must be at most 15 characters"
};

export const validateZip = {
  required: "Zip code is required",
  invalid: "Please enter a valid zip code"
};

export const validateProvince = {
  required: "City / Province is required",
  invalid: "Please enter a valid city / province"
};

export const validateAddress = {
  required: "Address is required",
  invalid: "Please enter a valid address"
};

export const validatePhoneNumber = {
  required: "Phone number is required",
  invalid: "Please enter a valid phone number",
  minLength: 4
};

export const valdiateDate = {
  required: "Date is required",
  invalid: "Please enter a valid date"
};

export const validateMessage = {
  required: "Message is required"
};

export const success = {
  newsletter: "Thanks for subscribing to our newsletter!",
  login: "You have successfully logged in!",
  signup: "Thanks for signing up. We sent you an email with a link to verify your account."
};

export const loginError = {
  wrong: "Wrong credentials",
  format: "Please enter a valid email address",
  empty: "We think you forgot to enter an email address"
};

export const forgotPasswordError = {
  notFound: "An account with that email doesnt exist"
};
