import { useEffect, useState } from "react";

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  if (typeof window === "undefined")
    return {
      width: undefined,
      height: undefined
    };

  const [windowSize, setWindowSize] = useState<{ width: number | undefined; height: number | undefined }>({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    setWindowSize(getWindowDimensions());
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
