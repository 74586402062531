"use client";

import styles from "./HeaderVideo.module.scss";
import Vimeo from "@u-wave/react-vimeo";
import useWindowDimensions from "@utils/UseWindowDimensions";

const HeaderVideo = ({ videoId, videoMobileId }: { videoId: string; videoMobileId?: string }) => {
  const { width } = useWindowDimensions();

  const currentVideoId = width && width < 768 && videoMobileId ? videoMobileId : videoId;

  // TODO: if no mobile video use desktop video styling

  return (
    <div className={styles.container}>
      <Vimeo
        className={styles.player}
        video={currentVideoId}
        muted={true}
        autoplay={true}
        responsive={true}
        loop
        controls={true}
        height={"100%"}
      />
    </div>
  );
};

export default HeaderVideo;
