"use client";

import React, { useContext, useState } from "react";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import * as Sentry from "@sentry/nextjs";
import styles from "./NewsletterForm.module.scss";
import TextInput, { InputDesign } from "@components/Input/TextInput";
import { ErrorTypes } from "@utils/Validation/getValidationMessage";
import Button, { ButtonDesign } from "@components/Common/Button";

export function NewsletterForm({
  inputDesign = "Pill",
  buttonDesign = "Gray",
  borderColor,
  fields,
  onSubmit
}: {
  inputDesign?: keyof typeof InputDesign;
  buttonDesign?: keyof typeof ButtonDesign;
  borderColor?: string;
  fields: {
    email?: boolean;
    company?: boolean;
    phone?: boolean;
  };
  onSubmit?: () => void;
}) {
  const { dictionary } = useContext(LocalizationContext);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      await fetch("/api/mail-list/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, phone, company })
      });

      setEmail("");
      setPhone("");
      setCompany("");
      onSubmit && onSubmit();

      setSent(true);

      setTimeout(() => {
        setLoading(false);
        setSent(false);
      }, 2000);
    } catch (e) {
      Sentry.captureException(e);
      console.log("an unexpected error occurred", e);

      setError(dictionary.errorUnknown);
    }
  };

  return (
    <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
      {fields.phone && (
        <TextInput
          design={inputDesign}
          borderColor={borderColor}
          name="phone"
          type={"text"}
          onChange={(value) => setPhone(value)}
          validation={{
            type: ErrorTypes.PhoneNumber,
            message: dictionary.invalidPhone
          }}
          value={phone}
          placeholder={dictionary.phone}
          className="stylePill"
        />
      )}

      {fields.company && (
        <TextInput
          design={inputDesign}
          borderColor={borderColor}
          name="company"
          type={"text"}
          onChange={(value) => setCompany(value)}
          validation={{
            type: ErrorTypes.Company,
            message: dictionary.invalidCompany
          }}
          value={company}
          placeholder={dictionary.company}
          className="stylePill"
        />
      )}

      {fields.email && (
        <TextInput
          design={inputDesign}
          borderColor={borderColor}
          name="email"
          type={"email"}
          onChange={(value) => setEmail(value)}
          validation={{
            type: ErrorTypes.Email,
            message: dictionary.emailInvalid
          }}
          required
          value={email}
          placeholder={dictionary.email}
          className="stylePill"
        />
      )}

      <div>
        {!error && (
          <>
            {!sent && (
              <>
                {!loading && (
                  <Button type={loading ? "Button" : "Submit"} design={buttonDesign} width="150px" height="40px">
                    {dictionary.send}
                  </Button>
                )}
                {loading && (
                  <Button type={loading ? "Button" : "Submit"} design={buttonDesign} width="150px" height="40px">
                    {dictionary.sending}
                  </Button>
                )}
              </>
            )}
          </>
        )}
        {error && (
          <>
            <Button type={loading ? "Button" : "Submit"} design={buttonDesign} width="150px" height="40px">
              {dictionary.send}
            </Button>
            <div className={styles.error}>
              <span>{error || dictionary.errorUnknown}</span>
            </div>
          </>
        )}
        {sent && (
          <Button type={loading ? "Button" : "Submit"} design={buttonDesign} width="150px" height="40px">
            <span>{dictionary.sent}</span>
          </Button>
        )}
      </div>
    </form>
  );
}
