"use client";

import styles from "./BlogNavigation.module.scss";
import React from "react";
import Link from "next/link";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";

const BlogNavigation = ({ currentPage = 1, maxPages = 1 }: { currentPage?: number; maxPages?: number }) => {
  const handleScroll = () => {
    if (typeof window !== "undefined") {
      const element = document.getElementById("blogPosts");

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const createNumbers = () => {
    const numbers = [];
    for (let i = 1; i <= maxPages; i++) {
      numbers.push(
        <Link
          key={i}
          className={currentPage === i ? styles.blogNavigationItemActive : styles.blogNavigationItem}
          href={{
            pathname: `articles`,
            query: { page: i }
          }}
          scroll={false}
          onClick={() => handleScroll()}
        >
          {i}
        </Link>
      );
    }
    return numbers;
  };

  return (
    <div className={styles.blogNavigation}>
      {currentPage > 1 && (
        <Link
          className={styles.blogNavigationLeft}
          href={{
            pathname: `articles`,
            query: { page: currentPage - 1 }
          }}
          scroll={false}
          onClick={() => handleScroll()}
        >
          <CaretLeft size={28} weight="thin" />
        </Link>
      )}

      {createNumbers()}

      {currentPage < maxPages && (
        <Link
          className={styles.blogNavigationRight}
          href={{
            pathname: `articles`,
            query: { page: currentPage + 1 }
          }}
          scroll={false}
          onClick={() => handleScroll()}
        >
          <CaretRight size={28} weight="thin" />
        </Link>
      )}
    </div>
  );
};

export default BlogNavigation;
