"use client";

import styles from "./Input.module.scss";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import getValidationMessage from "@utils/Validation/getValidationMessage";
import { InputTypes } from "@components/Input/enums";
import { DefaultInputType, InputError, InputInterface } from "@components/Input/interfaces";
import { LocalizationContext } from "@components/Localization/LocalizationProvider";
import { WarningCircle } from "phosphor-react";

export enum InputDesign {
  Underline = "underline",
  Pill = "pill",
  None = "none"
}

// Map the design to the corresponding class name
const designStyleMap: Record<InputDesign, string> = {
  [InputDesign.Underline]: styles.underline,
  [InputDesign.Pill]: styles.input,
  [InputDesign.None]: styles.none
};

const getStyle = (map: Record<InputDesign, string>, value?: keyof typeof InputDesign) =>
  value ? map[InputDesign[value]] || "" : "";

const TextInput = (
  {
    children,
    placeholder,
    name,
    label,
    description,
    value,
    type,
    autoComplete = true,
    readonly,
    disabled,
    maxLength,
    min,
    onChange,
    onClick,
    onKeyDown,
    onKeyUp,
    onBlur,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    className,
    design = "Underline",
    style,
    height,
    minHeight,
    width,
    minWidth,
    color,
    backgroundColor,
    borderColor,
    borderRadius,
    margin,
    padding,
    required,
    validation,
    onError,
    removeError,
    externalError
  }: InputInterface,
  ref: React.Ref<HTMLTextAreaElement | HTMLInputElement>
) => {
  const { dictionary } = useContext(LocalizationContext);

  const [error, setError] = useState<InputError | null>(externalError || null);

  useEffect(() => {
    if (externalError) {
      setError(externalError);
    }
  }, [externalError]);

  const onBlurValidation = () => {
    if (!validation) return;

    const message = getValidationMessage(validation.type, value.toString());
    if (message) {
      const newError = { type: validation.type, message };
      setError(newError);
      onError && onError(newError);
    } else {
      setError(null);
      removeError && removeError(validation.type);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    if (onChange) {
      onChange(value);
    }

    setError(null);
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (validation?.message) {
      onBlurValidation();
    }
    onBlur && onBlur(e);
  };

  const inlineStyles = {
    ...style,
    height: typeof height === "number" ? `${height}px` : height,
    minHeight: typeof minHeight === "number" ? `${minHeight}px` : minHeight,
    width: typeof width === "number" ? `${width}px` : width,
    minWidth: typeof minWidth === "number" ? `${minWidth}px` : minWidth,
    color,
    backgroundColor,
    borderColor,
    borderRadius: typeof borderRadius === "number" ? `${borderRadius}px` : borderRadius,
    margin: typeof margin === "number" ? `${margin}px` : margin,
    padding: typeof padding === "number" ? `${padding}px` : padding
  };

  const commonInputProps = {
    id: name,
    name,
    required,
    placeholder,
    className: `${className} ${getStyle(designStyleMap, design)}`,
    style: inlineStyles,
    value,
    onChange: handleChange,
    onKeyDown,
    onKeyUp,
    onClick,
    onBlur: handleBlur,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    readOnly: readonly,
    disabled,
    maxLength,
    min
  };

  // Define a default type

  // Safely convert the string type into the enum type
  const convertedType = InputTypes[type || DefaultInputType];

  return (
    <div className="form-group">
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          <small className={styles.description}>{description}</small>
        </label>
      )}
      <div className={styles.inputContainer}>
        {convertedType !== "textarea" ? (
          <input
            ref={ref as React.Ref<HTMLInputElement>}
            {...commonInputProps}
            type={type}
            autoComplete={autoComplete ? "on" : "off"}
          />
        ) : (
          <textarea ref={ref as React.Ref<HTMLTextAreaElement>} {...commonInputProps} />
        )}

        {error && (
          <div className={styles.error}>
            <span className={styles.errorText}>{error?.message || dictionary.errorUnknown}</span>
            <WarningCircle size={16} />
          </div>
        )}
        {children && <div className={styles.icon}>{children}</div>}
      </div>
    </div>
  );
};

const ForwardedTextInput = forwardRef<HTMLInputElement, InputInterface>(TextInput);

export default ForwardedTextInput;
