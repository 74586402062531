export enum InputTypes {
  text = "text",
  textArea = "textarea",
  password = "password",
  email = "email",
  number = "number",
  date = "date",
  time = "time",
  dateTime = "datetime-local",
  month = "month",
  week = "week",
  tel = "tel",
  url = "url",
  search = "search",
  color = "color"
}
